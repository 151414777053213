import React from 'react'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../../components/Layout'

class Election2020DirectorPage extends React.Component {
  render() {
    return (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1">2020 Directors Election</h1>
              <p>See documents and articles related to the 2020 directors election.</p>
              <hr />
              <div className="news-preview-container">

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Notice%20Regarding%20Candidate%20Filing%20Period.pdf">Notice Regarding Candidate Filing Period<br />Aviso Referente al Período de Presentación de Candidatura</a>
                    </p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Notice%20Regarding%20Candidate%20Filing%20Period.pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item" style={{maxWidth: '100%'}}>
                  <div className="news-preview-content">
                    <p className="is-size-5">
                      Election Information
                    </p>
                      <p>The next director’s election is scheduled for Saturday, May 2, 2020. The District will establish the polling place(s) for the election, which will be provided in the Notice of Election.</p>

                      <p>Applications for a place on the ballot may be filed from January 15, 2020, to 5:00 p.m. local time on February 14, 2020. For information about the requirements for filing for candidacy for a position on the Board, please visit the Texas Secretary of State’s candidate website, at <a href="https://www.sos.state.tx.us/elections/candidates/index.shtml">https://www.sos.state.tx.us/elections/candidates/index.shtml</a>.</p>

                      <p><strong>Candidate Application Filing Location:</strong><br />
                      Schwartz, Page &amp; Harding, L.L.P.<br />
                      1300 Post Oak Blvd. Ste. 1400<br />
                      Houston, Texas 77056<br />
                      (713) 623-4531<br/>
                      <a href="mailto:info@fbmud24.com">info@grandlakesmud4.com</a></p>
                  </div>
                </div>

              </div>
              <div className="section">
                <hr />
                <p><strong>English</strong><br />
                If you need this election document provided in Spanish, please send us a message requesting the translated document using the <Link to="/contact">“Contact Us”</Link> link on this website.</p>

                <p><strong>Spanish</strong><br />
                Si necesita que le entreguen este documento sobre la elección en español, envíenos un mensaje solicitando el documento traducido a través del enlace <Link to="/contact">“Contact Us”</Link> de este sitio web.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
}
}

export default Election2020DirectorPage
